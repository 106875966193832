.indicator-container {
    display: grid;
    --n: 4;
    grid-template-columns: repeat(auto-fill, minmax(max(17em, calc(100%/var(--n)) - .75em), 1fr));
    gap: .75em;
    margin-bottom: .75em;
    align-items: start;
}

.switch {
    font-size: .65em;
}

.button-container {
    display: flex;
    align-items: center;
    gap: .35em;
}
