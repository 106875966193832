.button-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.button {
  font-weight: 400;
  margin-left: .5em;
  font-size: .65em;
  width: 10em;
}

.date-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-button {
  font-weight: 400;
  margin-left: .5em;
  font-size: .65em;
}

.emoji-button {
  display: flex;
  height: 2em;
  width: 2em;
  /*font-size: .9em;*/
  justify-content: center;
  align-items: center;
}

.export-buttons {
  display: flex;
  gap: .5em;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0302294C;
}

.period {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.modal-container {
  display: flex;
  flex-direction: column;
  background: var(--modal-background);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 40em;
  margin: auto;
  overflow: auto;
  min-width: 30em;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 7px;
  padding: 1em 1em 1em 1em;
  /*overflow-y: scroll;*/
}
