
.container {
    display: flex;
    flex-direction: row;
    gap: .5em;
}

.form {
    min-width: 40em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fixed-column {
    width: 10em;
}

.emoji-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5em;
}

.emoji-button {
    font-size: medium;
}