.container {
    display: flex;
    flex-direction: column;
    gap: .5em;
    padding-bottom: .5em;
}

.new-investor {
    max-width: 30em;
}

.add-button {
    font-family: 'Courier New', Courier, monospace;
    /*margin-left: .5em;*/
    font-size: 1em;
    font-weight: 100;
    width: 2em;
    height: 1.5em;
    padding: 0;
}

.credit-button-container {
    display: flex;
    gap: .5em;
    align-items: center;
    padding-bottom: .25em;
}

.hidden-button {
    font-size: .7em;
}

.mini-table {
    min-height: 9em;
}

.switch-container {
    display: flex;
    gap: .5em;
    align-items: center;
}

.switch {
    font-size: .8em;
}

.chart-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: .75em;
    margin-bottom: .75em;
}

.indicator-container {
    display: grid;
    --n: 5;
    grid-template-columns: repeat(auto-fill, minmax(max(17em, calc(100%/var(--n)) - .75em), 1fr));
    gap: .75em;
    margin-bottom: .75em;
    align-items: start;
}
