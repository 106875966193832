/* .indicator-container {
  display: grid;
  --n: 6;
  grid-template-columns: repeat(auto-fill, minmax(max(1em, calc(100%/6) - .75em), 1fr));
  gap: .75em;
  margin-bottom: .75em;
} */

.indicator-container {
  display: grid;
  --n: 3;
  grid-template-columns: repeat(auto-fill, minmax(max(17em, calc(100%/var(--n)) - .75em), 1fr));
  gap: .75em;
  margin-bottom: .75em;
  align-items: start;
}

.balance-table {
  min-height: 10em;
  /*height: 20em;*/
}

.platform-logo {
  max-width: 1em;
}

.same-select-label {
  display: flex;
  gap: .5em;
  align-items: center;
  margin: .5em 0;
}

.image-table-container {
  display: grid;
  grid-template-columns: 20em 1.75fr;
  gap: .75em;
  margin-bottom: .75em;
}

.image {
  border-radius: 10px;
}

.chart-container {
  margin-bottom: .75em;
}

.fixed-column {
  width: 9em;
}

.balance-column {
  width: 12em;
}

.chart-switch {
  margin: 0 .5em !important;
  font-size: .8em;
}

.chart-filter {
  display: flex;
  align-items: center;
}

.no-image {
  opacity: 0.5;
  filter: var(--navbar-filter);
}

.button-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.button {
  font-weight: 400;
  margin-left: .75em;
  font-size: .65em;
  max-width: 15em;
}

.modal-button {
  font-weight: 400;
}

.row-table-container {
  margin-bottom: .75em;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: .25em;
}

.operation-burger {
  max-height: 1.25em;
}

.local-table {
  white-space: nowrap;
  margin-bottom: .75em;
}

.local-table td:first-child div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-filters > * {
  margin-left: .5em;
  font-size: .9em;
}

.link-container {
  width: 30em;
}
