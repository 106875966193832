.comment {
  align-self: flex-end;
}

.title-container {
  display: flex;
  align-items: center;
  gap: .5em;
}

.emoji-button {
  display: flex;
  height: 2em;
  width: 2em;
  /*font-size: .9em;*/
  justify-content: center;
  align-items: center;
}

.excel-logo {
  height: 1.1em;
}

.modal-container {
  display: flex;
  flex-direction: column;
  background: var(--modal-background);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 40em;
  margin: auto;
  overflow: auto;
  min-width: 30em;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 7px;
  padding: 1em 1em 1em 1em;
  /*overflow-y: scroll;*/
}

.operation-container {
  display: flex;
  flex-direction: column;
  gap: .25em;
}

.operation {
  padding: .5em;
  border-radius: 5px;
}

.operation:disabled {
  background: var(--bar-chart-secondary);
  color: var(--text-color);
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0302294C;
}

.modal-select {
  z-index: 2;
}

.payment-modal {
  height: 30em;
  width: 20em;
}

.period {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.platform-logo {
  max-width: 1em;
}

.operation-buttons {
  display: flex;
  gap: .5em;
  align-items: center;
  justify-content: space-between;
}

.buttons-group {
  display: flex;
  gap: .5em;
  align-items: center;
}

.export-buttons {
  display: flex;
  gap: .5em;
}

.export-mp {
  width: auto;
}

.export-all {
  flex-grow: 1;
}
