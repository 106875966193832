.container {
  display: flex;
  /* margin: 0 .75em .25em; */
  background: var(--modal-background);
  padding: .75em;
  border-radius: 10px;
}

.icon-halo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  background: #200e3219;
  border-radius: 50%;
  overflow: hidden;
}

.text-container {
  display: flex;
  flex-direction: column;
  margin-left: .75em
}

.value-container {
  display: flex;
  align-items: flex-end;
  font-weight: 800;
}

.value {
  font-size: 1.2em;
}

.diff-positive {
  margin-left: .75em;
  color: #3A974C;
  font-size: 1em;
}

.diff-negative {
  margin-left: .75em;
  color: #E71D36
}

.dev {
  background-image: repeating-linear-gradient(
          -45deg,
          #f2a417,
          #f2a417 15px,
          #645b57 15px,
          #645b57 30px
  );
}

.postfix {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-right: .75em;
}