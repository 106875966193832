.button-container {
    display: flex;
    align-items: center;
    gap: .5em;
}

.button {
    font-size: .65em;
    width: 10em;
    /*height: */
}

.indicator-container {
    display: grid;
    --n: 5;
    grid-template-columns: repeat(auto-fill, minmax(max(17em, calc(100%/var(--n)) - .75em), 1fr));
    gap: .75em;
    margin-bottom: .75em;
    align-items: start;
}

.chart-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: .75em;
    margin-bottom: .75em;
}

.switch-container {
    display: flex;
    gap: .5em;
    align-items: center;
}

.switch {
    font-size: .8em;
}

.emoji-button {
    font-size: .65em;
}

.edit-investor {
    max-width: 30em;
}

.bold {
    font-weight: 1000;
}
